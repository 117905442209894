<template>
  <div>
    <!-- <h1 style="position: absolute; top: 80px; z-index: 1">开发中...🔨</h1>
    词汇 -->
    <guide
      addPermissionString="ch:add"
      editPermissionString="ch:edit"
      deletePermissionString="ch:delete"
      viewPermissionString="ch:view"
      is-preview
      is-mask-closable
      ref="guide"
      hide-edit-btn
      :props-filters="{ typeId: 4 }"
    ></guide>
  </div>
</template>

<script>
import Guide from '@/views/system/modules/guide/guide-base'
export default {
  components: {
    Guide
  },
  created() {
    let { busId } = this.$route.query
    console.log('created', { busId })
    this.$bus.$on('/memberNotice/Regulation', this.highlight)
    if (busId) {
      setTimeout(() => this.$nextTick(() => this.$bus.$emit('/memberNotice/Regulation', busId)), 500)
    }
  },
  beforeDestroy() {
    this.$bus.$off('/memberNotice/Regulation', this.highlight)
  },
  data() {
    return {
      busId: null
    }
  },
  methods: {
    loadData() {
      let guide = this.$refs.guide
      console.log({ guide })
      if (guide) {
        let record = guide.dataSource.find(({ id }) => id == this.busId)
        this.busId = null
        console.log({ record })
        record && guide.openDetail(record)
      }
    },
    highlight(busId) {
      console.log('highlight', { busId })
      this.busId = busId
      let guide = this.$refs.guide
      console.log({ guide })
      if (guide) {
        guide.loadData()
        let record = guide.dataSource.find(({ id }) => id == busId)
        console.log({ record })
        record && guide.openDetail(record)
      }
    }
  }
}
</script>

<style></style>
